/* This file is used to configure the api host for the services. */

// this version uses the host the current page is served from
export const currentHost = `${window.location.protocol}//${window.location.host}/api`;
// this version is used for testing to force the usage of localhost:3000
export const localhost = "http://localhost:8000/api";
// export const mediahost = "http://localhost:8000";
export const mediahost = "https://admin.inmobiliariadaka.com";
//export const mediahost = "http://45.228.63.44:3360/";
//
export const devServer = "https://admin.inmobiliariadaka.com/api";
// export const devServer = "https://api.inmobiliariadaka.com";

// export the host to use
export default devServer;
